<template>
  <Page :title="teamName" :apiStatusList="[getTeamStatus]" :breadcrumbs="breadcrumbs">
		<template v-slot:subtitle v-if="hasSubtitle">
			<router-link :to="'/contests/'+ teamView.getContentPreview().getUrl()">
			{{contestName}}
			</router-link>
		</template>
		<team-detail :teamView="teamView" :contestUrl="teamView.getContentPreview().getUrl()" :editableContest="editableContest"></team-detail>
  </Page>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Loader from '@/components/Loader';
import Page from "../components/Page";
import TeamDetail from '../components/TeamDetail.vue';

export default {
	props: {
		teamId: {
			type: String,
			required: true,
		},
	},
  name: 'teamView',
  
  components: {
    Loader,
		Page,
		TeamDetail,
  },
  methods: {
    ...mapActions('user', ['getTeam']),
  },
  computed: {
    ...mapState('user', ['teamView', 'getTeamStatus']),
    ...mapState('timesync', ['serverTime']),
    ...mapGetters('user', ['user']),
    teamName() {
			console.log("TD ...", this.teamView);
			return this.teamView ? this.teamView
										.getName() : "";
		},
		hasSubtitle() {
			return this.teamView && this.teamView.getContentPreview().getTitle();
		},
		contestName() {
			return this.teamView ?
					this.teamView.getContentPreview().getTitle() : "";
		},
		breadcrumbs() {
			if(!this.teamView || !this.teamView.getContentPreview()) return null;
			return [
				{
					text: "Contests",
					href: "/contests",
				},
				{
					text: this.teamView.getContentPreview().getTitle(),
					href: "/contests/" + this.teamView.getContentPreview().getUrl()
				},
				{
					text: this.teamView.getName(),
					disabled: false,
				},
			]
		},
		editableContest() {
			var contentView = this.teamView.getContentPreview();
			var editableContest = contentView.getMetaData().getContestMeta() &&
			 contentView.getMetaData().getContestMeta().getTeamContestConfig() &&
				contentView.getMetaData().getContestMeta().getTeamContestConfig().getIsEditable();
				console.log("editableContest ....", editableContest);
				return editableContest;
		}
	 },
  created() {
    this.getTeam({ teamId: this.teamId })
		.then((this.loading = false));
  },
};
</script>

