var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Page', {
    attrs: {
      "title": _vm.teamName,
      "apiStatusList": [_vm.getTeamStatus],
      "breadcrumbs": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([_vm.hasSubtitle ? {
      key: "subtitle",
      fn: function fn() {
        return [_c('router-link', {
          attrs: {
            "to": '/contests/' + _vm.teamView.getContentPreview().getUrl()
          }
        }, [_vm._v(" " + _vm._s(_vm.contestName) + " ")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('team-detail', {
    attrs: {
      "teamView": _vm.teamView,
      "contestUrl": _vm.teamView.getContentPreview().getUrl(),
      "editableContest": _vm.editableContest
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }